var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "stepper stepper-selfscanning quantity-box" },
    [
      _c(
        "div",
        { staticClass: "stp", class: { "manual-input": _vm.inset.showInput } },
        [
          _c("div", { staticClass: "quantity-container" }, [
            _vm.inset.showInput === false
              ? _c(
                  "div",
                  {
                    staticClass: "qty quantity-value d-flex flex-row",
                    class: { "can-edit": _vm.canChangeMode() }
                  },
                  [
                    _vm.initQuantity != 0
                      ? _c(
                          "div",
                          {
                            staticClass: "edit",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.changeMode()
                              }
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.initQuantity) + " pz "),
                              _vm.initQuantityPerUnit != ""
                                ? _c("span", { staticClass: "smaller" }, [
                                    _vm._v(
                                      "(" +
                                        _vm._s(_vm.initQuantityPerUnit) +
                                        ")"
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c(
                              "v-icon",
                              {
                                staticClass: "ml-2",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.changeMode(true)
                                  }
                                }
                              },
                              [_vm._v("$edit")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.initQuantity == 0
                      ? _c("div", { staticClass: "qty-empty" }, [
                          _c("span", [_vm._v("--")])
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm.inset.showInput === true
              ? _c("div", { staticClass: "qty d-flex flex-row" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button button-clear button-energized",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.changeMode(true)
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("$close")])],
                    1
                  ),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inset.quantity,
                        expression: "inset.quantity"
                      }
                    ],
                    attrs: {
                      min: "0",
                      type: "number",
                      id: "val-input-" + _vm.item.product.productId
                    },
                    domProps: { value: _vm.inset.quantity },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.inset, "quantity", $event.target.value)
                      }
                    }
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "button button-clear button-energized",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.confirmEdit("selfscanning")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("$success")])],
                    1
                  )
                ])
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }