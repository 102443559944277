var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "popup-card" },
    [
      _vm.popup.title
        ? _c("v-card-title", { staticClass: "popup-title" }, [
            _vm._v(_vm._s(_vm.popup.title))
          ])
        : _vm._e(),
      _c(
        "v-card-text",
        { staticClass: "align-center options-selector" },
        [
          _c(
            "v-btn",
            {
              staticClass:
                "button button-energized button-long my-2 w-100 secondary",
              attrs: { depressed: "", color: "white", text: "", plain: "" },
              on: {
                click: function($event) {
                  return _vm.manualInput()
                }
              }
            },
            [_vm._v(" Inserimento manuale ")]
          ),
          _c(
            "v-btn",
            {
              staticClass:
                "button button-energized button-long my-2 w-100 secondary",
              attrs: { depressed: "", color: "white", text: "", plain: "" },
              on: {
                click: function($event) {
                  return _vm.removeItem()
                }
              }
            },
            [_vm._v(" Rimozione prodotto ")]
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "justify-end" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", depressed: "", "min-width": "150" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("Annulla")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }