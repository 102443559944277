var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "popup-card" },
    [
      _vm.popup.title
        ? _c("v-card-title", { staticClass: "popup-title" }, [
            _vm._v(_vm._s(_vm.popup.title))
          ])
        : _vm._e(),
      _c(
        "v-card-text",
        _vm._l(_vm.popup.lists, function(list) {
          return _c(
            "v-row",
            {
              key: list.id,
              attrs: { outlined: "", list: list },
              on: {
                click: function($event) {
                  return _vm.selectList(list)
                }
              }
            },
            [_c("v-col", [_vm._v(_vm._s(list.name))])],
            1
          )
        }),
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "justify-end" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", depressed: "", "min-width": "150" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("Annulla")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }