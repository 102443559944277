var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "popup-card selfscanning-manual-input-popup" },
    [
      _vm.popup.title
        ? _c("v-card-title", { staticClass: "popup-title" }, [
            _vm._v(_vm._s(_vm.popup.title)),
            _c("span", { staticClass: "title-box" }),
            _c("span", { staticClass: "subtitle-box" })
          ])
        : _vm._e(),
      !_vm.popup.removeItem
        ? _c(
            "v-card-text",
            [
              _vm.inputData.step == 1
                ? _c("v-text-field", {
                    attrs: { required: "" },
                    model: {
                      value: _vm.inputData.itemCode,
                      callback: function($$v) {
                        _vm.$set(_vm.inputData, "itemCode", $$v)
                      },
                      expression: "inputData.itemCode"
                    }
                  })
                : _vm._e(),
              _vm.inputData.step == 2
                ? _c("v-text-field", {
                    attrs: { required: "" },
                    model: {
                      value: _vm.inputData.itemQuantity,
                      callback: function($$v) {
                        _vm.$set(_vm.inputData, "itemQuantity", $$v)
                      },
                      expression: "inputData.itemQuantity"
                    }
                  })
                : _vm._e(),
              _c("span", [
                _vm._v(_vm._s(_vm.$n(_vm.inputData.product, "ssuffix")))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.popup.removeItem
        ? _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: { required: "" },
                model: {
                  value: _vm.inputData.removeCode,
                  callback: function($$v) {
                    _vm.$set(_vm.inputData, "removeCode", $$v)
                  },
                  expression: "inputData.removeCode"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-actions",
        { staticClass: "justify-end" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", depressed: "", "min-width": "150" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("Annulla")]
          ),
          !_vm.popup.removeItem
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    depressed: "",
                    "min-width": "150"
                  },
                  on: {
                    click: function($event) {
                      return _vm.addCode()
                    }
                  }
                },
                [_vm._v("Conferma")]
              )
            : _vm._e(),
          _vm.popup.removeItem
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    depressed: "",
                    "min-width": "150"
                  },
                  on: {
                    click: function($event) {
                      return _vm.removeItemByCode()
                    }
                  }
                },
                [_vm._v("Conferma")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }