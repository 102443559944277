var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "popup-card selfscanning-container" },
    [
      _vm.selfCart && _vm.selfCart.cartItems == 0
        ? _c("v-card-title", [
            _vm._v(
              ' Premi "Scansiona" per aggiungere prodotti al tuo carrello '
            )
          ])
        : _vm._e(),
      !_vm.selfCart
        ? _c("v-card-title", [
            _vm._v(
              ' Premi "Inizia" e scansiona il qrcode di inizio spesa per iniziare la tua spesa self '
            )
          ])
        : _vm._e(),
      _vm.selfCart &&
      _vm.selfCart.cartItems &&
      _vm.selfCart.cartItems.length > 0
        ? _c("v-card-text", [
            _c(
              "div",
              {
                staticClass:
                  "product-grid product-grid-selfscanning mt-2 mt-sm-0"
              },
              [
                _c(
                  "v-row",
                  _vm._l(_vm.selfCart.cartItems, function(cartItem, index) {
                    return _c(
                      "v-col",
                      {
                        key: cartItem.product.productId,
                        staticClass: "product-col",
                        attrs: {
                          cols: "12",
                          sm: "6",
                          md: "4",
                          lg: "3",
                          xl: "2"
                        }
                      },
                      [
                        _c("ProductCardSelfscanning", {
                          attrs: { item: cartItem, position: index }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-card-actions",
        [
          !_vm.selfCart
            ? _c(
                "v-row",
                {
                  staticClass:
                    "product-buy-actions d-flex align-center justify-space-between no-cart"
                },
                [
                  !_vm.initCode
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "button button-energized button-full my-2",
                              attrs: { width: "100%" },
                              on: { click: _vm.init }
                            },
                            [_vm._v("Inizia")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.selfCart
            ? _c(
                "v-row",
                {
                  staticClass:
                    "product-buy-actions d-flex align-center justify-space-between"
                },
                [
                  _vm.initCode
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column flex-end align-end",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("span", { staticClass: "cart-total" }, [
                            _vm._v("Totale: "),
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.$n(_vm.selfCart.grossTotal, "currency")
                                )
                              )
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.initCode
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { cols: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "button button-energized button-long my-2 secondary",
                              on: {
                                click: function($event) {
                                  return _vm.scan("add")
                                }
                              }
                            },
                            [_vm._v("Scansiona")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.initCode
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { cols: "6" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "button button-positive button-short my-2 primary",
                              on: {
                                click: function($event) {
                                  return _vm.showOptions()
                                }
                              }
                            },
                            [_vm._v("Opzioni")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { staticClass: "d-flex flex-column", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "button button-positive button-short my-2",
                          on: { click: _vm.abort }
                        },
                        [_vm._v("Annulla ordine")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "d-flex flex-column", attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "button button-positive button-short my-2",
                          on: { click: _vm.terminate }
                        },
                        [_vm._v("Concludi ordine")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }